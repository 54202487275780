//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faXmark } from '@fortawesome/pro-regular-svg-icons';

export default {
  name: 'OptimizeExperienceModal',
  components: {
    BaseImg: () => import('@motionelements/core/src/components/ui/BaseImg.vue'),
  },
  data() {
    return {
      faXmark,
      image: 'https://static.moele.me/img/studio-ai/optimize-experience.png?auto=format&w=280',
    };
  },
  mounted() {
    if (window.innerWidth < 1024) {
      const showModal = !JSON.parse(sessionStorage.getItem('dontShowOptimizeExperienceModal'));
      if (showModal) {
        this.$bvModal.show('optimize-experience-modal');
      }
    }
  },
  methods: {
    closeModal() {
      sessionStorage.setItem('dontShowOptimizeExperienceModal', JSON.stringify(true));
      this.$bvModal.hide('optimize-experience-modal');
    },
  },
};
